<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <div style="margin-bottom: 18px">
      <a-button type="primary" icon="plus" @click="toEditRecord"
        >写日记</a-button
      >
      <a-input-search
        placeholder="全局搜索"
        style="width: 300px; float: right"
        v-model="globalSearch"
        @search="onSearch"
      />
    </div>
    <a-table
      ref="table"
      style="width: 100%"
      size="small"
      :rowKey="(record) => record.record_id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      bordered
    >
      <span slot="record_id" slot-scope="text">
        <router-link
          target="_blank"
          :to="{
            path: '/daily_life/detail',
            query: { record_id: text },
          }"
          >{{ text }}</router-link
        >
      </span>
      <span slot="create_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="update_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot-scope="text" slot="record_type">
        <a-tag :color="text | recordTypeColor">{{
          text | recordTypeStatus
        }}</a-tag>
      </span>
      <span slot="content_type" slot-scope="text">
        <a-tag :color="text | contentTypeColor">{{
          text | contentTypeStatus
        }}</a-tag>
      </span>
      <span slot="action" slot-scope="record">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 8px"
          @click="
            newTarget({
              path: '/daily_life/edit',
              query: { record_id: record.record_id },
            })
          "
          >编辑</a-button
        >
        <a-button
          type="danger"
          size="small"
          @click="deleteRecord(record.record_id)"
          >删除</a-button
        >
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { getRecord, deleteRecord } from "@/api/daily_life";
import { changeTime } from "@/libs/utils";
import consts from "@/constants/daily_life";
import { Modal } from "ant-design-vue";
export default {
  data() {
    this.changeTime = changeTime;
    return {
      searchKey: "search",
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize),
      },
      loading: false,
      current_page_size: 10,
      globalSearch: "",
      data: [],
      // 表头
      columns: [
        {
          title: "编号",
          dataIndex: "record_id",
          ellipsis: true,
          scopedSlots: { customRender: "record_id" },
          width: 80,
        },
        {
          title: "记录内容",
          dataIndex: "record_content",
          ellipsis: true,
          width: 80,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          sorter: true,
          scopedSlots: { customRender: "create_time" },
          width: 80,
        },
        {
          title: "更改时间",
          dataIndex: "update_time",
          sorter: true,
          scopedSlots: { customRender: "update_time" },
          width: 80,
        },
        {
          title: "记录属性",
          dataIndex: "record_type",
          scopedSlots: { customRender: "record_type" },
          filters: [
            {
              text: "日记",
              value: 0,
            },
            {
              text: "周记",
              value: 1,
            },
            {
              text: "月记",
              value: 2,
            },
            {
              text: "年记",
              value: 3,
            },
          ],
          filterMultiple: false,
          width: 50,
        },
        {
          title: "内容属性",
          dataIndex: "content_type",
          scopedSlots: { customRender: "content_type" },
          filters: [
            {
              text: "工作",
              value: 0,
            },
            {
              text: "生活",
              value: 1,
            },
          ],
          filterMultiple: false,
          width: 50,
        },
        {
          title: "操作",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  filters: {
    recordTypeStatus(type) {
      return consts.recordTypeList[type].label;
    },
    recordTypeColor(type) {
      return consts.recordTypeList[type].color;
    },
    contentTypeStatus(type) {
      return consts.contentTypeList[type].label;
    },
    contentTypeColor(type) {
      return consts.contentTypeList[type].color;
    },
  },
  methods: {
    deleteRecord(record_id) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除吗?",
        onOk: () => {
          deleteRecord(record_id)
            .then((res) => {
              console.log(res.data);
              this.$message.success("删除成功");
              const dataSource = [...this.data];
              this.data = dataSource.filter(
                (item) => item.record_id !== record_id
              );
            })
            .catch((err) => {
              this.$message.error(err);
            });
        },
        onCancel() {},
      });
    },
    toEditRecord() {
      let routeData = this.$router.resolve({ path: "/daily_life/edit" });
      window.open(routeData.href, "_blank");
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.current_page_size = pagination.pageSize;
      this.fetch({
        page_size: pagination.pageSize,
        page: pagination.current,
        ...filterDic,
        ...orderingDic,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.globalSearch) {
        params["search"] = this.globalSearch;
      }
      params["page_size"] = this.current_page_size;
      getRecord(params)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
    onSearch(value) {
      this.globalSearch = value;
      this.fetch({ page: 1 });
    },
    newTarget(router) {
      let route = this.$router.resolve(router);
      window.open(route.href, "_blank");
    },
  },
  mounted() {
    let params = { page: 1 };
    this.fetch(params);
  },
};
</script>
