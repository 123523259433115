var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"content",staticStyle:{"width":"100%"},attrs:{"bordered":false}},[_c('div',{staticStyle:{"margin-bottom":"18px"}},[_c('a-button',{attrs:{"type":"primary","icon":"plus"},on:{"click":_vm.toEditRecord}},[_vm._v("写日记")]),_c('a-input-search',{staticStyle:{"width":"300px","float":"right"},attrs:{"placeholder":"全局搜索"},on:{"search":_vm.onSearch},model:{value:(_vm.globalSearch),callback:function ($$v) {_vm.globalSearch=$$v},expression:"globalSearch"}})],1),_c('a-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"size":"small","rowKey":(record) => record.record_id,"columns":_vm.columns,"data-source":_vm.data,"showPagination":"auto","pagination":_vm.pagination,"loading":_vm.loading,"bordered":""},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"record_id",fn:function(text){return _c('span',{},[_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/daily_life/detail',
          query: { record_id: text },
        }}},[_vm._v(_vm._s(text))])],1)}},{key:"create_time",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.changeTime(text))+" ")])}},{key:"update_time",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.changeTime(text))+" ")])}},{key:"record_type",fn:function(text){return _c('span',{},[_c('a-tag',{attrs:{"color":_vm._f("recordTypeColor")(text)}},[_vm._v(_vm._s(_vm._f("recordTypeStatus")(text)))])],1)}},{key:"content_type",fn:function(text){return _c('span',{},[_c('a-tag',{attrs:{"color":_vm._f("contentTypeColor")(text)}},[_vm._v(_vm._s(_vm._f("contentTypeStatus")(text)))])],1)}},{key:"action",fn:function(record){return _c('span',{},[_c('a-button',{staticStyle:{"margin-right":"8px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.newTarget({
            path: '/daily_life/edit',
            query: { record_id: record.record_id },
          })}}},[_vm._v("编辑")]),_c('a-button',{attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.deleteRecord(record.record_id)}}},[_vm._v("删除")])],1)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }