export default {
  recordTypeList: {
    0: {
      value: 0,
      label: "日记",
      color: "#108ee9",
    },
    1: {
      value: 1,
      label: "周记",
      color: "#87d068",
    },
    2: {
      value: 2,
      label: "月记",
      color: "#2db7f5",
    },
    3: {
      value: 3,
      label: "年记",
      color: "#f50",
    },
  },
  contentTypeList: {
    0: {
      value: 0,
      label: "工作",
      color: "#f50",
    },
    1: {
      value: 1,
      label: "生活",
      color: "#108ee9",
    },
  },
};
